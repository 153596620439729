.tablContLayout {
  height: 45vh;
}

.tableColHeaderStyle {
  background-color: #079073;
}

.tableColHeaderTextStyle {
  color: #ffffff !important;
  text-align: left !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  font-style: normal !important;
}

.table-Data-White-Bg-Color {
  background-color: #ffffff;
}

.table-Data-grey-Bg-Color {
  background-color: #d1d9d3;
}

.table-Data-grey-text-Color {
  color: #4a4747 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  font-style: normal;
}

.table-Data-green-text-Color {
  color: #079073 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  font-style: normal ;
}
