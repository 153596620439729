.headerStyle {
  text-align: left;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
}

.contentStyle {
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
}

.btnContainerLayout {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.yesBtnLayout {
  margin-left: 15px;
}
