.login-container {
  width: 100%;
  height: 50vh;
  background: #e9ebf0 0% 0% no-repeat padding-box;
  border: 1px solid #f0f4fa;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #e9eef7;
  opacity: 1;
}

.aiImgLayout {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 72px;
}

.login-header-style {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  font-style: normal;
  color: #1c1c1c;
  margin-top: 40px;
  margin-bottom: 10px;
  letter-spacing: 0;
  opacity: 1;
}

.login-description-style {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  font-style: normal;
  color: #1c1c1c;
  letter-spacing: 0;
  opacity: 1;
}

.btn-layout {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.btn-style {
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #f0f4fa;
  border-radius: 4px;
  background: #18749c 0% 0% no-repeat padding-box;
  letter-spacing: 0px;
  color: #ffffff;
  box-shadow: 0px 3px 6px #e9eef7;
  opacity: 1;
  font-weight: 500;
}

.error{
  color: red;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center;
}