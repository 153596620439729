.botRatingBox {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.botRatingDescription {
  font-weight: 500;
}

.botRatingRadio {
  accent-color: red;
}
