.chatBoxWithSidebar {
  height: 100%;
}

.chatBoxWithoutSidebar {
  height: 100%;
}

.chatboxWrapper {
  display: flex;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  padding: 10px;
  flex-direction: column;
  align-items: center;
}

.light-chatboxWrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
}

.dark-chatboxWrapper {
  background: #232d3f 0% 0% no-repeat padding-box;
}

.conversationTop {
  display: flex;
  align-items: center;
  width: 100%;
}

.conversation {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  flex: 1;
  display: flex;
  align-items: flex-end;
}

.chatBoxHr {
  border: 1px solid gray;
  width: 95%;
  margin: 15px 0 20px 0;
}

.queryBox {
  width: 100%;
}

.userInputField {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.userInputPrompt {
  font-size: 20px;
  padding: 10px;
  font-weight: 500;
}

.light-userInputPrompt {
  color: #1e1e1e;
}

.dark-userInputPrompt {
  color: #ffffff;
}

.inputField {
  width: 100%;
  padding: 5px 20px;
  height: 40px;
  border-radius: 41px;
  font-size: 20px;
  outline: 0;
  outline-offset: 0;
}

.inputField:disabled {
  cursor: not-allowed;
}

.light-inputField {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #000;
  color: #1e1e1e;
}

.dark-inputField {
  background: #565869 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  color: #c5c5d2;
}

.sendButton {
  padding: 10px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000000;
  border-radius: 50%;
  cursor: pointer;
}

.sendButton:disabled {
  cursor: not-allowed;
}

.openIconStyle {
  float: left;
  margin-right: 10px;
  margin-top: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
}
