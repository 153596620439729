body {
  margin: 0;
  font-family: Poppins, sans-serif;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #c5c5d2;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #c5c5d2;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #c5c5d2;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c5c5d2;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #c5c5d2;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #c5c5d2;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: rgb(179, 179, 179) 0% 0% no-repeat padding-box;
  border-radius: 5px;
}
