.layout {
  display: flex;
  height: 100vh;
}

.sidebarContainer {
  width: 30%;
}

.chatboxContainer {
  flex: 1;
}
