.sidebar {
  height: 100%;
}

.light-sidebar {
  background: #02c2a2 0% 0% no-repeat padding-box !important;
}

.dark-sidebar {
  background: #000000 0% 0% no-repeat padding-box !important;
}

.closeIconStyle {
  float: right;
  margin-right: 10px;
  margin-top: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.aiImg {
  border-radius: 10px;
  width: 100%;
  margin-top: 20px;
}

.headText {
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
}

.light-headText {
  color: #1c1c1c;
}

.dark-headText {
  color: #ffffff;
}

.btnStyle {
  display: flex;
  padding: 14px 40px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  outline: none;
}

.light-btnStyle {
  background: #d9d9d9 0% 0% no-repeat padding-box;
  color: #1c1c1c;
  border: 1px solid #1c1c1c;
}

.dark-btnStyle {
  background: #700b97 0% 0% no-repeat padding-box;
  color: #ffffff;
  border: 1px solid #ffffff;
}

.btnLayout {
  padding: 5px;
  display: flex;
  justify-content: center;
}

.layoutPosition {
  position: fixed;
  width: 30%;
  bottom: 0;
}

.settingLayout,
.logoutLayout {
  display: flex;
  align-items: center;
  height: 60px;
  cursor: pointer;
}

.settingStyle,
.logoutStyle {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  padding-left: 10px;
}

.light-settingStyle,
.light-logoutStyle {
  color: #1c1c1c;
}

.dark-settingStyle,
.dark-logoutStyle {
  color: #ffffff;
}
