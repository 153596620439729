.message {
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.message-own {
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  align-items: flex-end;
}

.messageContent {
  display: flex;
  gap: 10px;
}

.message-own .messageContent {
  flex-direction: row-reverse;
}

.messageImg {
  padding: 8px;
  background-color: aquamarine;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
}

.light-messageText {
  margin: 0;
  padding: 10px;
  max-width: 500px;
  border-radius: 0 10px 10px;
  font-size: 16px;
  font-weight: 500;
  background: rgba(209, 211, 216, 0.16) 0% 0% no-repeat padding-box;
  color: #1c1c1c;
  border: 1px solid #1e1e1e;
}

.dark-messageText {
  margin: 0;
  padding: 10px;
  max-width: 500px;
  border-radius: 0 10px 10px;
  font-size: 16px;
  font-weight: 500;
  background: #005b41 0% 0% no-repeat padding-box;
  color: #ffffff;
  border: 1px solid #1e1e1e;
}

/* .messageText::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    } */

.message-own .light-messageText {
  background: #e8e8e8 0% 0% no-repeat padding-box;
  color: #1c1c1c;
  border: 1px solid #1e1e1e;
  border-radius: 10px 0 10px 10px;
}

.message-own .dark-messageText {
  background: #008170 0% 0% no-repeat padding-box;
  color: #ffffff;
  border: 1px solid #1e1e1e;
  border-radius: 10px 0 10px 10px;
}

/* .message.own .messageText::before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    } */

.refTitle {
  width: fit-content;
  background-color: #909090;
  border: 1px solid #000;
  padding: 10px;
  border-radius: 10px;
}

.refInfo {
  margin: 5px;
}
