.suggestionWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  border-radius: 20px;
}

.light-suggestionWrapper {
  background: #02b1e8 0% 0% no-repeat padding-box;
  border: 2px solid #1c1c1c;
}
.dark-suggestionWrapper {
  background: #af0404 0% 0% no-repeat padding-box;
  border: 2px solid #1c1c1c;
}

.suggestionText {
  font-weight: 500;
}

.light-suggestionText,
.dark-suggestionText {
  color: #fff;
}
