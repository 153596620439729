.login-container-layout{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.login-layout{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    width: 25%;
}